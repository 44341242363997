import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import style from './ActivityMeter.module.css';
import {
  FiDownload,
  FiPackage,
  FiPlus,
  FiRepeat,
  FiSearch,
  FiSettings,
  FiUpload
} from 'react-icons/fi';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const ActivityMeter = ({ data }: any) => {
  const visible_activites = [
    'PICKING',
    'STORING',
    'INSPECTION',
    'TRANSFER',
    'PICK_FOR_CONSOLIDATION',
    'CONFIG',
    'INSERT'
  ];

  const activity_verb = (activity: any) => {
    switch (activity) {
      case 'PICKING':
        return 'Picked';
      case 'STORING':
        return 'Stored';
      case 'INSPECTION':
        return 'Searched';
      case 'TRANSFER':
        return 'Moved';
      case 'PICK_FOR_CONSOLIDATION':
        return 'Consolidated';
      case 'INSERT':
        return 'Inserted bin';
      case 'CONFIG':
        return 'Configured bin';
    }
  };

  const activities = data.filter((activity: any) =>
    visible_activites.includes(activity.activity)
  );

  const sortActivitiesByType = (visible_activites: any, activities: any) => {
    activities.sort((a: any, b: any) => {
      const aKey = Object.values(a)[0];
      const bKey = Object.values(b)[0];
      return visible_activites.indexOf(aKey) - visible_activites.indexOf(bKey);
    });
  };
  sortActivitiesByType(visible_activites, activities);

  activities.sort((a: any, b: any) => {
    const aDate = Date.parse(a.last_seen);
    const bDate = Date.parse(b.last_seen);
    return bDate - aDate;
  });

  let total_activity_time = 0;
  let last_seen_timeago = 'Inactive';
  let last_seen_activity = '' as any;

  if (activities.length > 0) {
    total_activity_time = activities.reduce(
      (acc: number, activity: any) => acc + parseInt(activity.time),
      0
    );

    last_seen_activity = activities.reduce((prev: any, current: any) => {
      let prev_date = new Date(prev.last_seen);
      let current_date = new Date(current.last_seen);
      return prev_date > current_date ? prev : current;
    });

    const last_seen_date = dayjs.utc(last_seen_activity.last_seen);
    last_seen_timeago = last_seen_date.fromNow();
  }

  return (
    <div className={style.root}>
      <p
        className={`${style.status} ${last_seen_timeago === 'Inactive' ? style.inactive : ''}`}
      >
        {activity_verb(last_seen_activity.activity)} {last_seen_timeago}
      </p>
      <div className={style.activities}>
        {activities.length > 0 ? (
          activities.map((activity: any) => (
            <div
              key={activity.activity}
              data-activity={activity.activity}
              className={style.activity}
              style={
                {
                  '--value': `${total_activity_time > 0 ? (activity.time / total_activity_time) * 100 : 0}%`
                } as React.CSSProperties
              }
            >
              <div className={style.type}>
                {activity.activity === 'PICKING' ? (
                  <FiUpload size={18} />
                ) : activity.activity === 'STORING' ? (
                  <FiDownload size={18} />
                ) : activity.activity === 'INSPECTION' ? (
                  <FiSearch size={18} />
                ) : activity.activity === 'CONFIG' ? (
                  <FiSettings size={18} />
                ) : activity.activity === 'INSERT' ? (
                  <FiPlus size={18} />
                ) : activity.activity === 'TRANSFER' ? (
                  <FiRepeat size={18} />
                ) : activity.activity === 'PICK_FOR_CONSOLIDATION' ? (
                  <FiPackage size={18} />
                ) : (
                  ''
                )}
              </div>
            </div>
          ))
        ) : (
          <div
            className={style.activity}
            data-activity="none"
            style={{ '--value': '100%' } as React.CSSProperties}
          ></div>
        )}
      </div>
    </div>
  );
};

export default ActivityMeter;
